<template>
  <v-container fluid>
    <v-row class="my-10">
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
      <v-col>
        <payment-successful-card></payment-successful-card>
      </v-col>
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
    </v-row>
    <v-row :align="'center'" :justify="'center'" no-gutters>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <router-button
          :text="$t('connection.backHome')"
          redirect="/overview"
        ></router-button>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
/* global fbq */
import PaymentSuccessfulCard from "../../components/card/PaymentSuccessfulCard.vue";
import RouterButton from "../../components/button/RouterButton.vue";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
export default {
  name: "payment-success-page",
  components: {
    PaymentSuccessfulCard,
    RouterButton,
  },
  computed: {
    ...mapFields("payment", ["paymentData"]),
    getItemName() {
      if (
        this.paymentData &&
        this.paymentData.pricingPlanType == "YOUNGPLATFORM"
      ) {
        return (
          this.paymentData.pricingPlanType +
          "_" +
          this.paymentData.pricingPlanName
        );
      } else if (this.paymentData && this.paymentData.taxYear) {
        return (
          this.paymentData.pricingPlanType + "_" + this.paymentData.taxYear
        );
      } else {
        return this.paymentData.pricingPlanType;
      }
    },
  },
  methods: {
    ...mapActions("payment", ["verifyPayment"]),
  },
  async mounted() {
    // Extract session_id from URL
    const sessionId = this.$route.query.session_id;
    console.log(sessionId);

    if (sessionId) {
      // Call backend to verify Stripe payment
      this.verifyPayment(sessionId)
        .then(() => {
          if (this.paymentData) {
            const eventData = {
              transaction_id: this.paymentData.transactionId, // Stripe session ID or order ID
              value: this.paymentData.totalAmount,
              currency: this.paymentData.currency,
              items: [
                {
                  item_name: this.getItemName,
                  item_id: this.getItemName,
                  price: this.paymentData.totalAmount,
                  quantity: 1,
                },
              ],
            };
            // Send purchase event to GA4
            this.$gtag.event("purchase", eventData);
            if (typeof fbq !== "undefined") {
              fbq("track", "Purchase", {
                value: this.paymentData.totalAmount,
                currency: "EUR",
              });
            }
          }
        })
        .catch((error) => {
          console.error("Payment verification failed:", error);
        });
    }
  },
};
</script>

<style>
</style>